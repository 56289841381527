import React from "react";
import BannerTwo from "../components/BannerTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import ContactAreaFour from "../components/ContactAreaFour";
import AboutAreaFour from "../components/AboutAreaFour";
import AboutAreaOne from "../components/AboutAreaOne";
import Service from "../components/services";
import ServiceAreaFive from "../components/ServiceAreaFive";
import CaseStudyAreaTwo from "../components/CaseStudyAreaTwo";
import Brochure from "../components/brochure";
const HomeTwo = () => {
  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarTwo />

      {/* Banner Two */}
      <BannerTwo />
      
      {/* About Area Two */}
      <AboutAreaFour />


      {/* About Area Two */}
      <AboutAreaOne />

      {/* About Area Two */}
      <ServiceAreaTwo />

      {/* Services */}
      <Service />

      {/* service area five */}
      <ServiceAreaFive />

      {/*  case study area two */}
      <CaseStudyAreaTwo />
      
      
      <ContactAreaFour />

      {/* Footer Two */}
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
