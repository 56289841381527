import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeFour from "./pages/HomeFour";
import HomeOne from "./pages/HomeOne";
import HomeThree from "./pages/HomeThree";
import HomeTwo from "./pages/HomeTwo";
import Service from "./components/services";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeFive from "./pages/HomeFive";
import ProjectsGallery from "./pages/Projects";
import ContactAreaFour from "./components/ContactAreaFour";
import Brochure from "./components/brochure";
import AboutAreaFour from "./components/AboutAreaFour";
import AboutAreaOne from "./components/AboutAreaOne";
import ServiceAreaTwo from "./components/ServiceAreaTwo";
import ServiceAreaFive from "./components/ServiceAreaFive";
import CaseStudyAreaTwo from "./components/CaseStudyAreaTwo";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeTwo />} />
        <Route exact path='/projects' element={<ProjectsGallery />} />
        <Route exact path='/About' element={<AboutAreaFour />} />
        <Route exact path='/Details' element={<AboutAreaOne />} />
        <Route exact path='/Services' element={<Service />} />
        <Route exact path='/Blog' element={<ServiceAreaFive />} />
        <Route exact path='/Gallery' element={<CaseStudyAreaTwo />} />
        <Route exact path='/Contact' element={<ContactAreaFour />} />
        <Route exact path='/brochure' element={<Brochure />} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
