import React from "react";
import { useState } from "react";
import { useEffect } from "react";
const AboutAreaFour = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-110'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner p-xl-5 pt-4 '
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img
                  className='animate-main-img'
                  src='assets/img/about/14.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-10px" : "auto"}}
                />
                <img
                  className='main-img m-md-4 animate-img-1 top_image_bounce'
                  src='assets/img/about/About.png'
                  alt='img'
                  style={{borderRadius:'10px', marginLeft: windowWidth <= 768 ? "-15vh" : "auto", marginTop: windowWidth <= 768 ? "2rem" : "auto" }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 align-self-center '
              data-aos='fade-left'
              data-aos-delay='750'
              data-aos-duration='4000'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                  Leading in <span>Façade </span>{" "}
                  Systems: Superior Manufacturing & Services
                </h2>
                <p className='content mb-4 mb-xl-5'>
                  Founded in 2000, Creative Façade World excels in high-quality facade systems. Specializing in Aluminium, MS, and SS, we offer comprehensive support from design to installation. Our experienced team ensures precision in both new and existing buildings, emphasizing customer needs with specialized facade engineering.
                </p>
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/Quality.svg' alt='img' />
                  </div>
                  <div className='media-body'>
                    <div className='single-progressbar'>
                      <h6>Quality</h6>
                      <div className='skill'>
                        <div
                          style={{ width: "100%" }}
                          className='skill-bar skill1 wow slideInLeft animated'
                        ></div>
                        <p className='skill-count1'>
                          <span className='counter'>100</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/Cost.svg' alt='img' />
                  </div>
                  <div className='media-body'>
                    <div className='single-progressbar'>
                      <h6>Cost Effective Solutions</h6>
                      <div className='skill'>
                        <div
                          style={{ width: "100%" }}
                          className='skill-bar skill1 wow slideInLeft animated'
                        ></div>
                        <p className='skill-count1'>
                          <span className='counter'>100</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
