import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/Services.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  We <span>Provide:</span>
                </h2>
                <p className='content mb-4 mb-xl-5'>
                  <ul>
                    <li>Curtain Wall Systems</li>
                    <li>Unitized and Semi-Unitized Glazing Aluminium Windows and Doors</li>
                    <li>ACP and other metal cladding</li>
                    <li>Stone and Clay Cladding systems</li>
                    <li>Casement Windows</li>
                    <li>Spider Glazing Systems</li>
                    <li>Stick Glazing</li>
                    <li>Shower Enclosure</li>
                    <li>Sliding and Automatic Door installations</li>
                    <li>Foldable and Revolving Doors</li>
                    <li>Arched / Curved Windows</li>
                    <li>Frameless Partition</li>
                    <li>UPVC doors and Windows</li>
                    <li>All types MS and SS Fabrication work</li>
                    <li>Skylights - Glass, Polycarbonate and Metal ceilings</li>
                    <li>SS and MS railings and stair-cases</li>
                    <li>Bullet resistant installations and Fire resistant solutions</li>
                    <li>Value Engineering</li>
                  </ul>

                </p>

              </div>
            </div>
          </div>
        </div >
      </div >
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
