import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";

const FooterTwo = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area footer-area-2 bg-gray mt-0 pd-top-120'>
        <div className='container'>
          <div className='row g-5'>
            <div className='col-lg-3 col-md-6 mb-4'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo.png' alt='img' />
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 mb-4'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Address</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                    <li>
                      <FaMapMarkedAlt />
                      Reg Office: 1st Floor, No. 401, Sri Rameshwara Complex, Dr. Shivaram Karanth Nagar, Above Mana Restaurant, Byrathi, Bangalore-560077.
                    </li>
                    <li>
                      <FaMapMarkedAlt />
                      Branch office: 1st floor, Near steel Centre, Azizuddin Road, Bunder, Mangalore -575001
                    </li>
                    <li>
                      <FaMapMarkedAlt />
                      22, Ram Niwas,  opp Tikitar Company, Village road, Bhandup, Mumbai- 400078
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 mb-4'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Contact Us</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                    <li className='mt-3'>
                      <FaPhoneAlt />
                      <a href='tel:+917022044442'>+91 7022044442</a>
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope />
                      <a href='mailto:antu@creativefacadeworld.com'>antu@creativefacadeworld.com</a>
                    </li>
                  </ul>
                  <ul className='social-media mt-4'>
                    <li>
                      <a href='https://m.facebook.com/groups/1520708492109370/?ref=share&mibextid=I6gGtw'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.instagram.com/creativefacadeworld/'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='https://wa.me/917022044442' target='_blank' rel='noopener noreferrer'>
                        <FaWhatsapp />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© Creative Facade World {currentYear} | All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
