import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const ContactAreaFour = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleInputChange = (e) => {
    // A generic input change handler that updates the state based on the input name
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "contact":
        setContact(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("email", email);
    formData.append("message", message);

    try {
      const response = await fetch("https://formcarry.com/s/s0BUyw3Baog", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Form submitted successfully!");
        setFormSubmitted(true);
        setFormError(false);
      } else {
        console.error("Failed to submit form");
        setFormSubmitted(false);
        setFormError(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormSubmitted(false);
      setFormError(true);
    } finally {
      // Reset form values
      setName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      <div className="contact-area pd-top-90 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-lg-end "
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner ms-xl-5 p-xl-5 pt-4 ">
                <img
                  className="animate-main-img"
                  src="assets/img/about/18.png"
                  alt="img"
                />
                <img
                  className="main-img m-4 top_image_bounce"
                  src="assets/img/about/19.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 order-lg-first "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-5 mt-lg-0">
                <h6 className="sub-title">GET IN TOUCH</h6>
                <h2 className="title">
                  Send Us A <span>Message</span>
                </h2>
                <p className="content">
                  For your Project, we will do everything.
                </p>
                {formSubmitted ? (
                  <p className="content">
                    THANK YOU FOR CONTACTING CREATIVE FACADE WORLD
                  </p>
                ) : (
                  <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name Here"
                            value={name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="contact"
                            placeholder="Contact Here"
                            value={contact}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email Here"
                            value={email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            name="message"
                            placeholder="Message"
                            value={message}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-border-gray mt-0"
                        >
                          Send <FaPlus />
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {formError && (
                  <p className="content">
                    Thank You For Contacting Creative Facade World!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactAreaFour;
