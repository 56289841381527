import React, { useState, useEffect } from "react";
import { FaWhatsapp, FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavbarTwo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [active, setActive] = useState(false);

  const menuActive = () => {
    setActive(!active);
  };

  // Control sidebar navigation
  const handleSubMenuClick = (event) => {
    const subMenu = event.target.parentElement.querySelector(".sub-menu");
    if (subMenu) {
      subMenu.classList.toggle("active");
      event.target.classList.toggle("open");
    }
  };

  return (
    <>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className='navbar navbar-area navbar-area-2 navbar-expand-lg'>
        <div className='container nav-container'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={`menu toggle-btn d-block d-lg-none ${active && 'open'}`}
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/logo.png' alt='img' style={{ height: windowWidth <= 768 ? "40px" : "" }} />
            </Link>
          </div>
          <div
            className={`collapse navbar-collapse ${active ? "sopen" : ""}`}
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              {windowWidth <= 768 && (
                <li className='menu-item'>
                  <Link to='/Contact'>Contact</Link>
                </li>
              )}
              <li className='menu-item-has-children'>
                <a href='#' onClick={handleSubMenuClick}>
                  Chat with us
                </a>
                <ul className='sub-menu'>
                  <li>
                    <a href='https://wa.me/917022044442' target='_blank' rel='noopener noreferrer'>
                      <FaWhatsapp /> Whatsapp
                    </a>
                  </li>
                  <li>
                    {/* Directly add the Instagram link */}
                    <a href='https://www.instagram.com/creativefacadeworld?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank' rel='noopener noreferrer'>
                      <FaInstagram /> Instagram
                    </a>
                  </li>
                  <li>
                    <a href='https://m.facebook.com/groups/1520708492109370/?ref=share&mibextid=I6gGtw'>
                      <FaFacebookF /> Facebook
                    </a>
                  </li>
                </ul>
              </li>
              <li className='menu-item'>
                <Link to='/projects'>Projects</Link>
              </li>
              <li className='menu-item'>
                <a
                  href="../../assets/brochure/cfw.pdf"  // Assuming you have a PDF file
                  download="cfw.pdf"
                >
                  Brochure
                </a>
              </li>
            </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop align-self-center'>
            <Link className='btn btn-border-base' to='/Contact'>
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
