import React from 'react';

const Brochure = () => {
    const pngImages = [...Array(4).keys()]; // Assuming you have 4 PNG images

    return (
        <div>
            {pngImages.map((index) => (
                <div key={index}>
                    <img src={`../../assets/brochure/${index}.png`} alt={`img ${index}`} />
                    <a
                        href={`../../assets/brochure/${index}.png`}
                        download={`img_${index}.png`}
                    >
                    </a>
                </div>
            ))}
            <a
                href="../../assets/brochure/cfw.pdf"  // Assuming you have a PDF file
                download="cfw.pdf"
                className="btn btn-border-base "
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="1500"
                style={{margin:'2vh'}}
            >
                Download PDF
            </a>
        </div>
    );
};

export default Brochure;
