import { React, useState, useEffect } from "react";

const Service = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}


      {/* FACADE GLAZING */}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >

                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Facade Glazing.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  FACADE <span>GLAZING</span>
                </h2>
                <p>Facade glazing is a design and construction technique that involves the incorporation of glass panels into the exterior of a building. This method not only contributes to the aesthetic appeal of the structure but also facilitates the infusion of natural light into interior spaces, creating an open and inviting atmosphere. Facade glazing is often employed to enhance the visual appeal of commercial and residential buildings, providing a modern and transparent facade.</p>
              </div>
            </div>
          </div>
        </div>




        {/* SPIDER GLAZING */}
        <div className='container' style={{ marginTop: '150px', marginBottom: '100px' }}>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  SPIDER <span>GLAZING</span>
                </h2>
                <p>Spider glazing is an architectural approach that utilizes specialized metal fittings known as spider fittings to support glass panels without the need for visible framing. This technique creates a seamless and frameless appearance, making it a popular choice for modern and minimalist designs. Spider glazing is often employed in the construction of glass facades, canopies, and other architectural elements where a sleek and unobtrusive aesthetic is desired.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >

                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Spider glazing.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Curtain Wall System GLAZING */}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >

                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Curtain Wall System Glazing.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto", }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  CURTAIN WALL SYSTEM <span>GLAZING</span>
                </h2>
                <p>Curtain wall system glazing refers to the installation of a non-structural outer covering on a building's facade, typically composed of glass panels. This system allows for the creation of a continuous and uniform appearance while providing benefits such as energy efficiency, weather resistance, and sound insulation. Curtain walls are commonly used in contemporary architecture to enhance both the visual and functional aspects of a building's exterior.</p>
              </div>
            </div>
          </div>
        </div>




        {/*SEMI UNITIZED FACADE GLAZING*/}
        <div className='container' style={{ marginTop: '150px', marginBottom: '100px' }}>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  SEMI UNITIZED <span>FACADE GLAZING</span>
                </h2>
                <p>Semi unitized facade glazing is an innovative construction method that combines the efficiency of prefabrication with on-site installation. In this approach, building facade units, incorporating both glass and framing components, are partially assembled off-site before being transported and installed on the building's exterior. This method streamlines the construction process while maintaining a high level of precision and quality in the finished facade.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >

                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Semi utilized Facade Glazing.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* HPL FACADE CLADDING */}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >

                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/HPL Facade Clading.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  HPL <span>FACADE </span>CLADDING
                </h2>
                <p>High-Pressure Laminate (HPL) facade cladding involves the application of multi-layered resin-treated paper onto the exterior of a building. The layers are then compressed under high pressure and heat, resulting in a durable and aesthetically pleasing finish. HPL facade cladding is known for its versatility, offering a wide range of colors, textures, and patterns, making it a popular choice for enhancing the visual appeal of commercial and residential structures.</p>
              </div>
            </div>
          </div>
        </div>




        {/*GLASS SHOWER CUBICLES*/}
        <div className='container' style={{ marginTop: '150px', marginBottom: '100px' }}>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  GLASS <span>SHOWER </span>CUBICALS
                </h2>
                <p>Glass shower cubicles are contemporary enclosures designed for bathrooms, featuring walls made entirely or partially of glass. These cubicles provide a modern and open feel to the bathroom space, allowing natural light to permeate while maintaining functionality and privacy. Glass shower cubicles contribute to a clean and spacious aesthetic, creating a visually appealing and practical solution for modern bathroom designs.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >


                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Glass Shower Cubicals.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* GLASS RAILINGS AND SKYLIGHTS */}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >


                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Glass Railings and Sky Lights.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  GLASS RAILINGS <span>& </span> SKYLIGHTS
                </h2>
                <p>Glass railings are transparent barriers made of glass that are commonly used in architectural designs for safety on balconies, staircases, and terraces. These railings offer an unobstructed view, allowing for a seamless connection between indoor and outdoor spaces. Skylights, on the other hand, are roof openings covered with glass, strategically positioned to introduce natural light into interior spaces. Both glass railings and skylights contribute to an open and well-lit environment within a structure.</p>
              </div>
            </div>
          </div>
        </div>




        {/*ACP CLADDING SYSTEM*/}
        <div className='container' style={{ marginTop: '150px', marginBottom: '100px' }}>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  ACP <span>CLADDING </span>SYSTEM
                </h2>
                <p>Aluminum Composite Panel (ACP) cladding is a building envelope material that consists of two aluminum sheets bonded to a core, typically made of polyethylene. This composite material is renowned for its lightweight nature, durability, and versatility. ACP cladding systems are employed to enhance the aesthetics of buildings while providing weather resistance and insulation. They come in various colors and finishes, offering architects and designers a wide range of options for exterior applications.
                </p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/cladding.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />

              </div>
            </div>
          </div>
        </div>
      </div>




      {/* ELEVATION DESIGNING AND GLASS SERVICES */}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >


                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Elevation Designing and Glass Services.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  ELEVATION DESIGNING<span> &</span> GLASS SERVICES
                </h2>
                <p>Elevation designing encompasses the planning and execution of the exterior appearance of a building. Architects and designers carefully consider factors such as aesthetics, functionality, and environmental impact to create an elevation that aligns with the overall design vision. Glass services, in the context of construction and architecture, encompass a spectrum of activities including glass installation, customization, maintenance, and repair. These services play a crucial role in achieving the desired aesthetic and functional outcomes in buildings that incorporate extensive glass elements.</p>
              </div>
            </div>
          </div>
        </div>




        {/*STRUCTURAL SUPPORTS*/}
        <div className='container' style={{ marginTop: '150px', marginBottom: '100px' }}>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  STRUCTURAL <span>SUPPORTS </span>
                </h2>
                <p>In the realm of glazing, structural supports are the components designed to bear the load and provide stability to glass installations. These supports can include metal frames, brackets, and other load-bearing elements strategically integrated into the building's structure. The choice of structural supports is critical to ensure the safety and longevity of the glass elements, especially in areas with varying environmental loads such as wind, snow, and seismic forces.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >


                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Structural Supports.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* GLASS STRUCTURAL GLAZING */}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >

                <img
                  className='main-img animate-img-1 top_image_bounce'
                  src='assets/img/about/Glass Structural Glazing.png'
                  alt='img'
                  style={{ marginLeft: windowWidth <= 768 ? "-16vh" : "auto" }}
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h2 className='title'>
                  GLASS <span> STRUCTURAL</span> GLAZING
                </h2>
                <p>Glass structural glazing is an architectural technique that involves using glass as a primary structural element in a building. In this method, large glass panels are integrated into the building's framework, serving not only as an aesthetic feature but also as load-bearing elements. Glass structural glazing creates a seamless and modern appearance, allowing architects to design spaces that are flooded with natural light. This technique is popular in contemporary architecture, offering a harmonious blend of form and function.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default Service;
