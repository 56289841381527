import React from "react";
import { Link } from "react-router-dom";

const ServiceAreaTwo = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h2 className='title'>
                  OUR <span>ADVANCED</span> SERVICES
</h2>
                <p></p>                <p>Experience meticulous attention to detail and precise installation with our services, seamlessly integrating into your architectural vision. We prioritize solutions that optimize daylight penetration and ensure optimal thermal comfort. Elevate your space with a diverse range of glazing options, from sleek modern designs to timeless classics, enhancing your property's visual allure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
