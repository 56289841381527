import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const images = [
  "./assets/img/bg/1.jpg",
  "./assets/img/bg/2.jpg",
  "./assets/img/bg/3.jpg",
  "./assets/img/bg/4.jpg",
];

const BannerTwo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(0); // the current image index
  const bannerRef = useRef(null); // a reference to the banner-area element

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, []); // run the effect only once

  useEffect(() => {
    bannerRef.current.style.backgroundImage = `url(${images[index]})`;
  }, [index, windowWidth]); // run the effect whenever the index or window width changes

  // Define your dynamic styles based on window width here
  const dynamicStyles = {
    Container: {
      paddingBottom: windowWidth <= 768 ? "30vh" : "30vh",
    },
    Title: {
      marginTop: windowWidth <= 768 ? "-10vh" : "",
      fontSize: windowWidth <= 768 ? "26px" : "",
    },
    subtitle: {
      marginTop: windowWidth <= 768 ? "-10vh" : "",
      marginBottom: windowWidth <= 768 ? "12vh" : "",
      display: windowWidth <= 768 ? "flex" : "",
      width: windowWidth <= 768 ? "fit-content" : "",
      fontSize: windowWidth <= 768 ? "12px" : "",
    },
    Body: {
      marginTop: windowWidth <= 768 ? "-1rem" : "",
      marginBottom: windowWidth <= 768 ? "" : "",
      display: windowWidth <= 768 ? "" : "",
      fontSize: windowWidth <= 768 ? "15px" : "",
      color: "#FFFFFF",
    },
    Button: {
      marginTop: windowWidth <= 768 ? "-1rem" : "",
      padding: windowWidth <= 768 ? "-3rem" : "",
      width: windowWidth <= 768 ? "fit-content" : "",
      color: "#ffffff",
    },
    main_image: {
      marginTop: windowWidth <= 768 ? "-0.8rem" : "3rem",
      marginLeft: windowWidth <= 768 ? "-30%" : "-10vh",
      height: windowWidth <= 768 ? "40vh" : "",
      borderRadius: "10px",
      paddingTop: "-5px",
      position: "absolute",
      zIndex: "5",
      boxShadow: "0 0 40px 0 rgba(0,0,0,1)",
    },
  };
  return (
    <>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-2 bg-cover"
        ref={bannerRef} // assign the reference to the banner-area element
      >
        <div className="container" style={dynamicStyles.Container}>
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle "
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                  style={dynamicStyles.subtitle}
                >
                  Designing for the future
                </h6>
                <h2
                  className="title "
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                  style={dynamicStyles.Title}
                >
                  {" "}
                  CREATIVE <span>FACADE </span> WORLD
                </h2>
                <p
                  className="content pe-xl-5 "
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  style={dynamicStyles.Body}
                >
                  Elevate your project's success with our in-depth knowledge of glazing systems – ensuring a blend of functionality and aesthetic appeal.
                </p>
                <Link
                  className="btn btn-border-base "
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                  to="/Projects"
                  style={dynamicStyles.Button}
                >
                  See Our Projects<FaPlus />
                </Link>
                <div
                  className="d-inline-block align-self-center "
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >

                  <div></div>
                </div>

              </div>
            </div>
            <div
              className="col-lg-6 col-md-9 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="banner-thumb-2 mt-4 mt-lg-0 " style={{ width: windowWidth <= 768 ? "600px" : "auto",}}>
                <div className="main-img-wrap">
                  <img
                    className="main-img animate-img-1 top_image_bounce"
                    src="assets/img/banner-2/1.png"
                    alt="img"
                    style={dynamicStyles.main_image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
    </>
  );
};

export default BannerTwo;
