import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect} from "react";
const ServiceAreaFive = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/*=================== service area five start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h2 className='sub-title-sky-blue'>FORGING AHEAD</h2>
                <h6 className='title' style={{fontSize: windowWidth <= 768 ? "20px" : "",}}>
                  CREATIVE FACADE WORLD IS COMMITTED TO OFFERING PREMIUM RAILINGS SYSTEMS, FACADE GLAZING, SHOWER CUBICALS & MUCH MORE......
                </h6>
              </div>
            </div>
          </div>
          <div className='row custom-no-gutter'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/Quality.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>Quality</Link>
                  </h5>
                  <p className='mb-0'>
                    Our each design is engineered with utmost precision, so that we can guarantee the quality of products. The creative crackerjacks ensure style, uniqueness and elegance in each product, to make it a statement addition to your space.
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/Robust.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>Robust</Link>
                  </h5>
                  <p className='mb-0'>
                    Each of our designs is meticulously crafted with unparalleled precision, ensuring products that are not only straightforward but also robust. Our creative experts, true crackerjacks in their field, infuse style, uniqueness, and elegance into every product, transforming each into a statement addition to your space.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none'>
              <div className='service-thumb service-middle-section align-self-end animate-img-3 top_image_bounce'>
                <img src='assets/img/service-icon/Main.png' alt='img' />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/Cost.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>Cost Effective</Link>
                  </h5>
                  <p className='mb-0'>
                    Each crafted design exemplifies a harmonious blend of simplicity and durability, promising not only robustness but also cost-effective solutions. Our team of creatives, true visionaries in their craft, is dedicated to instilling style, uniqueness. We prioritize not just quality but also affordability, making our offerings unique and budget-friendly additions to your project.
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/Fast.png' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/service-details'>Fast Delivery</Link>
                  </h5>
                  <p className='mb-0'>
                    Impeccable design, swift delivery. Crafted with dedication for style and durability, our products promise expedited service. Elegance, no compromise on speed.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFive;
