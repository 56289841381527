import React, { useEffect, useState } from 'react';

const ProjectsGallery = () => {
  // Update the regular expression to filter only ".jpg" images
  const imagesContext = require.context(
    '../../public/assets/Projects',
    true,
    /\.(jpg)$/
  );
  const folderNames = Array.from(new Set(imagesContext.keys().map((key) => key.split('/')[1])));

  // Create state for image data objects
  const [imageObjects, setImageObjects] = useState({});

  useEffect(() => {
    // Load image data and create object URLs
    const loadImageData = async (imageKey) => {
      const imageResponse = await fetch(imagesContext(imageKey));
      const imageBlob = await imageResponse.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setImageObjects((prevImages) => ({ ...prevImages, [imageKey]: imageUrl }));
    };

    for (const imageKey of imagesContext.keys()) {
      loadImageData(imageKey);
    }

    // Cleanup on unmount
    return () => {
      Object.values(imageObjects).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [imagesContext]);

  // Use a regular function to define the event handler
  function toggleSubMenu(event) {
    // Use event.target to access the element that triggered the event
    const subMenu = event.target.parentElement.querySelector('.sub-menu');
    if (subMenu) {
      // Toggle the classList of the sub-menu element
      subMenu.classList.toggle('active');
    }
  }

  return (
    <div>
      {folderNames.map((folderName) => (
        <div key={folderName}>
          <h2>{folderName}</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {imagesContext.keys().map((imageKey) => {
              const imageName = imageKey.split('/').pop();
              const folderOfImage = imageKey.split('/')[1];

              if (folderOfImage === folderName) {
                const imageUrl = imageObjects[imageKey]; // Use state for image URL

                return (
                  <img
                    key={imageName}
                    src={imageUrl}
                    alt={imageName}
                    style={{ width: '25%', boxSizing: 'border-box', padding: '5px' }}
                    // Add an onClick event listener to the image element
                    onClick={toggleSubMenu}
                  />
                );
              }

              return null; // Don't render if not in the folder
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectsGallery;
